body{
	font-family: $font-family;
}

h1,h2,h3,h4,h5,h6,p{
	font-family: $font-family;
}

p{
	margin: 0;
}

a{
	color: #000000;
}

.owner-base{
	background-color: $owner-color !important;

	.tabs__bar{
		background-color: $owner-color-home-tab !important;
		width: 80%;
		margin: 0 auto;
		border-radius: 2px;

		a{
			color: #ffffff;		
		}
	}
}

.user-base{
	background-color: $user-color !important;

	.tabs__bar{
		background-color: $user-color !important;
	}
}

button{
	&.btn-yellow{
		background-color: $yellow-color !important;
		color: $light-color;
	}
	&.btn-lime{
		background-color: $lime-color !important;
		color: $light-color;
	}
	&.btn-orange{
		background-color: $orange-color !important;
		color: $light-color;
	}
	&.btn-dark-green{
		background-color: $dark-green-color !important;
		color: $light-color;
	}
	&.btn-light-red{
		background-color: $light-red-color !important;
		color: $light-color;
	}
}