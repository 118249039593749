/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255,255,255);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

nav{
	// z-index: 10002 !important;
  z-index: 5 !important;
	&.toolbar{
		.toolbar__content{
			// height: unset !important;
			height: auto !important;

			.home-icon, .bars-icon{
				i{
					font-size: 30px;
				}
			}

			.img-logo{
				margin-top: 10px;
				height: 46px;
				margin-bottom: 10px;
			}
		}
	
		// .container{
		// 	padding-top:17.5px;
		// 	padding-bottom:17.5px;
		// 	padding-left:0;
		// 	padding-right:0;
		// 	.row{
		// 		div{
		// 			&.flex{
		// 				padding-top:0;
		// 				padding-bottom:0;
		// 				display: flex;
		// 				&:first-child{
		// 					img{
		// 						height: 26px;
		// 						align-self: center;
		// 					}
		// 				}
		// 			}
		// 			&.logo{
		// 				align-self:center;

		// 				.toolbar__title{
		// 					font-size: 24px;
		// 					line-height:28px;
		// 					font-family: $font-family;
		// 				}
		// 			}
		// 		}
		
				
		// 	}
		// }
	}
}

.dialog__content__active{
	// z-index: 10003 !important;
}

.confirmDialog{
  
}

.navigation-drawer{
	margin-top: 66px !important;
	z-index: 10004 !important;
}

.content{
	padding-top: 66px !important;
}

.menu__content {
  z-index: 4 !important;
}

.croppa-container{
  z-index: 4;
	margin: 10px auto;
	display: block !important;

  &.croppa--has-target{
    background-color: transparent !important;
  }
  
  &.storelogo-cropper{
    width: 200px;
    height: 200px;

    canvas{
      border-radius: 50%;
    }
  }

  &.storephoto-cropper{
    max-width: 720px;
    width: 100%;
    height: 200px;
  }
}

button{
	&.btn-translate{
		font-size: 12px;
	}
}

a{
  &.no-line{
    text-decoration: none;
  }
}

.italic{
  font-style: italic;
}