// Colors
$dark-color: #000000;
$light-color: #ffffff;
$text-color: #636b6f;
$lime-color: #94D058;
$orange-color: #F08300;
$yellow-color: #F9BE34;
$dark-green-color: #3D993B;
$light-red-color: #F57878;
$owner-color: $lime-color;
$owner-color-home-tab: $yellow-color;
$user-color: $orange-color;
$disabled-color: #CCCCCC;

// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: 'Noto Sans JP', sans-serif;
$font-family: 'Noto Sans JP', sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;

// Navbar
$navbar-default-bg: $light-color;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: $light-color;

$coupon-container-background-color: rgb(245,245,245);