.img-responsive{
	max-width: 100%;
	height: auto;
}

nav{
	.logo{
		img{
			max-height: 45px;
			padding-top: 5px;
		}
	}
}

.pre{
	white-space: pre-wrap;
}
.wrap-text{
	white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word; 
}
.break-word{
	word-break: break-word;
}

.navigation-drawer{
	.to-owner-page{
		position: absolute;
		// right: 50%;
		bottom: 10%;
		left: 0;
		right: 0;
		// -ms-transform: translate(50%, 10%);
	 //    -webkit-transform: translate(50%, 10%);
	 //    transform: translate(50%, 10%);
	}
}

#storelistpage{
	.card{
		box-shadow: none;
	}
}
.store-list{
	padding: 17px 10px;

	&:first-child{
		border-top: 1px solid #979797;
	}
	&:not(:last-child){
		border-bottom: 1px solid #979797;
	}
	&:last-child{
		border-bottom: 1px solid #979797;
	}
}

.qrscan{
	display: none;
}

.top-message-form{
	.user-register, .owner-register, .owner-login{
		.welcome{
			p{
				font-size: 18px;
				color: $orange-color;
				margin-bottom: 25px;
			}
		}
		.title{
			p{
				font-size: 18px;
				color: $dark-color;
				font-weight: bold;
			}
			span{
				font-size: 13px;
			}
		}
	}
}

.user-tabs{
	.tabs__bar{
		background-color: #C4C4C4 !important;
	}
	.tabs__item--active{
		background-color: #F9BE34;
	}
	.tabs__div{
		a{
			letter-spacing: 15px;
			color: #ffffff;
		}
	}
}

.store-list-container{
	.search-field{
		background-color: rgba(3, 3, 3, 0.09);
	}
	.store-list{
		border-top:none !important;
		.list__tile__action{
			font-size: 13px !important;
			color: #F08300 !important;
			min-width: 80px;
		    justify-content: center !important;
		    text-align: center;
		}
		.list__tile__content{
			margin-right: 7%;
		}
	}
}

.store-info-module{
	h3{

	}

	.store-info-title-container{
		margin-top: -50px;
		margin-left: 110px;
		
		.store-info-title{
			font-size: 18px;
			font-weight: 600;
		}
		.store-info-industry{
			font-size: 12px;
		}
	}

	.store-info-map-container{
		p{
			font-size: 11px;
			line-height: 16px;
			color: #717171;
		}
	}
}

#postingpage{
	.post-form{
		box-shadow: none;
		.header{
			position: relative;
			.header-image{
				min-height: 90px;			
			}
			.title{
				width:100%; 
				justify-content: center; 
				color: #ffffff;
			    background-color: rgba(0,0,0,0.3);
			    padding-bottom: 51px;
			    // padding: 87px 10px 10px 10px;
			    // padding-bottom: 42px;
			    h3{
			    	font-size: 18px !important;
			    	text-transform: uppercase;
			    }
			    p{
			    	font-size: 12px !important;
			    	margin-bottom: 0;
		    	 	white-space: pre-wrap;
		    	 	line-height: 16px;
			    }
			}
			.store-name {
				// margin-top: 90px;
				// margin-bottom:-40px;
			}
			.store-logo{
				position: absolute;
				bottom: -33.5px;
				left: 0;
				right: 0;
				margin: 0 auto;
				height: 67px;
				width: 67px;
				background-size: contain;
				border-radius: 50%;
				z-index: 1;
			}
		}
		.owner-message{
			p{
				font-weight: 300;
				white-space: pre-wrap;
				font-size: 12px;
			}
		}
		.form-container{
			.upload-image-container{
				background-color: #DADADA;
				padding-left: 35px;
				padding-right: 35px;
				padding-bottom: 0px;

				.picture-input-container{
					.picture-preview{
						max-width: 100%;
						height: 50px;
						margin-left: 8px;
						margin-right: 8px;

						img{
							max-height: 100%;
						}
					}

					.submit-button{
						background-color: #F9BE34;
						max-width: 136px;
						width: 100%;

						.btn__content{
							justify-content: flex-start;
						}

						img{
							position: absolute;
							right: 13px;
						}
					}
				}
				.remove-picture-button{
					position: relative;
					height: 40px;
					z-index: 99999;
				}

			}
			.textarea-container{
				background-color: #DADADA;
				padding-top: 53px;
				padding-left: 35px;
				padding-right: 35px;
				padding-bottom: 0px;

				.input-group{
					padding: 0 !important;
				}

				textarea{
					background-color: #ffffff !important;
					border-radius: 5px;
					padding: 12px;
				}
				.input-group__details:before{
					background-color: transparent !important;
				}

			}
		}
		.thank_you{
			padding-top: 66px;
			padding-left: 33px;
			padding-right: 33px;
			h3{
				font-size: 18px !important;
				letter-spacing: 0.2rem !important;
				color: #222222 !important;
				margin-bottom: 15px;
				font-weight: bold;
			}
			p{
				font-size: 14px;
				color: #000000;
			}
			.register{
				margin-top: 30px;
			}
		}
	}
}

.store_sns{
	img{
		width: 46px;
		height: 46px;
		margin: 5px 10px;
	}
}

@media screen and (max-width: 768px){
	#postingpage{
		.post-form{
			.thank_you{
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}
}
.get-coupon-container{
	background-color: $coupon-container-background-color;
    border: dashed 3px #F08300;
	padding: 10px !important;
	max-width: 350px;
	margin: 25px auto;

	h3{
		text-transform: uppercase;
		margin-bottom: 0 !important;
	}
	p{
		margin-bottom: 0;
		font-weight: 400;
	}
	.term-get-coupon{
		span{
			font-size: 11px;
		    font-weight: 300;
		    white-space: pre-wrap;
		}
	}
}
.usercouponlist-module{
	h4.coupon-empty{
		color: #bdbdbd;
		font-weight: 400;
	}
	.coupon-tile-container{
		text-align: center;
		.coupon-tile{
			display: inline-block;
			min-width: 350px;
			margin: 20px;
			vertical-align: top;
		}
	}
}
@media only screen and (max-width: 400px) {
	.usercouponlist-module{
		.coupon-tile-container{
			.coupon-tile{
				max-width: 350px;
			    min-width: 258px;
			    margin: 10px;
			}
		}
	}
}

#postingrecords{
	.review-tile{
		font-size: 0.9em;

		&:not(:last-child){
			margin-bottom: 10px;
		}
		.list__tile{
			height: auto;
			
			.review_content{
				.review_title{
					font-weight: bold;
					font-size: 14px;
					span{
						font-weight: normal;
					}

				}
			}
		}
		.answer {
			font-size: 18px;
		}
		.favorite-icon{
			font-size: 30px !important;
		}
	}
}

#question-list-module{
	.questionListTile{
		&.sortable-chosen{
			background-color: #ccc;
		}
	}
}

.alert-font {
	color: red;
}

.card_expand {
	height: auto !important;
}

.postingrecords_icon {
	height: 1px;
}

.postingrecords_subtitle {
	margin-left: 33px !important;
	white-space: inherit !important;
}

.scroll-loading {
	margin-left: 50%;
}

.store-info-logo {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	// margin-left: 80%;
	margin-top: -70px;
	z-index: 1;
}

#mappicker-module{
	.google-map-container{
		width:100%;
		height:100%;
		// height:-webkit-fill-available;
		// height:-moz-available;
		// height:fill-available;
		height:stretch;
	}

	.pac-container {
		z-index: 20000;
	}

	.search-map-form input[type=text] {
		width: 60%;
		height: 30px;
		padding: 5px;
		border: solid 1px #ccc;
		border-radius: 5px;
	}
}

.map-btn-done-bottom{
	max-width: 200px;
	width: 100%;
}

#ownerreply{
	.title{

	}
	.contents{
		.informations{
			.store_name{
				background-color: #94D058;
				color: #ffffff;
				line-height: 26px;
				padding-left: 10px;
				font-weight: bold;
			}
		}
	}
}

.usercouponlist-module {
	table {
		img {
			max-width: 150px;
			vertical-align: middle;
		}

		tr{
			&.invalid{
				background-color: $disabled-color;
				opacity: 0.5;
			}
		}
	}
}

.coupon-box{
  	background-color: #eee;
	position: relative;
	max-width: 360px;

	.card {
		border-radius: 4px;
		background-color: whitesmoke !important;

		.used-stamp{
			z-index: 1;
			position: absolute;
			top: 0;
			right: 0;
			width: 120px;
			height: 120px;

			img{
				width: 100%;
			}
		}

		.card__media{
			min-height: 70px !important;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}

		.coupon-title{
			z-index: 2;
			h2{
			    line-height: 24px;
			    font-size: 22px;
			    font-weight: 600;
			    text-transform: uppercase;
			    text-align: left;
			}
			p{
				font-size: 12px !important;
				font-weight: 300;
			}
		}
		.coupon-store-name{
			p{
				font-weight: 400;
			}
		}
		.coupon-time{
			p{
				font-size: 12px !important;
				text-align: left;

				span{
					font-size: 12px;
					font-weight: 400;
				}

				&.coupon-valid-date{
					line-height: 40px;
					margin-bottom: 0;
				}
			}
		}

		.card__media__content{
			position: relative;
			min-height: 70px !important;
		}
		.card__media__content:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #000000;
			opacity: 0.4;
			z-index: 0;
		}

		.no-coupon-image{
			// background-color: orange;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}

		.card__text{
			&.top-part{
			    background-color: #F08300;
			    border-bottom-left-radius: 4px;
			    border-bottom-right-radius: 4px;

			    p{
			    	color: #fff !important;
			    }
			}
		}

		.coupon-link-container{
			margin: 6px 0;
		}
		.coupon-link{
			padding: 5px;
		    background-color: whitesmoke;
		    font-size: 10px;
		    min-height: 40px;
		    border-radius: 4px;
		    text-align: center;
		    border: solid 1px #F08300;
			// box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);

			a{
				color: #F08300;
			}

			&.white-border{
				background-color: #ffffff;
				border: 1px solid #ffffff;
			}
		}
		.coupon-email-form{
			label{
				font-size: 0.9em;
			}
			.input-group{
				input{
					font-size: 14px;
				}
				&.input-group--error {
					.input-group__messages{
						font-size: 10px;
					}
				}
			}
			.coupon-mailbtn{
				height: 30px;
				min-width: 70px;
				min-width: auto;
			}
		}

		button{
			&.btn-coupon-detail{
				background-color: #fff;
				color: #F08300;
				height: 45px;
			}
			&.coupon-mailbtn{
				background-color: #F08300 !important;
				color: #fff;
			}
		}
		.btn__content {
			max-width: 100%;
			white-space: normal;
		}
	}
}

#questionaire-module {
	.radio-group{
		label{
			word-break: break-word;
		}
	}
}

.owner-questionnaire-list-module{
	.card{
		.card__text{
			hr{
				flex: unset;
			}
		}
	}

	.btn-answers{
		padding: 5px 10px;
    	height: auto;
    	background-color: transparent !important;
	    box-shadow: none !important;
	    border: solid #ff9800 2px;
	    border-radius: 5px;

    	.btn__content{
    		padding: 0;

    		&:before{
    			background-color: transparent !important;
    		}

    		span{
			    margin-left: 10px;
			    padding-left: 10px;
			    border-left: solid 1px #000000;
			    color: #f44336;
    		}
    	}

    	&:hover{
    		background-color: #ff9800 !important;
    		color: #ffffff;
    	}
	}
}

.trans-selection-wrapper{
	button{
		display: inline-block;
		vertical-align: top;
	}

	.trans-select{
		display: inline-block;
		vertical-align: top;
		width: 45%;
	}
}

.answer-question-list-module,
.answer-user-list-module{
	.list{
		.list__tile{
			height: auto;
		}
	}
	.filter-container{
		.daterange-filter-wrapper{
			display: inline-block;

			.answer-daterangepicker {
				border: solid 1px #000;
			    border-radius: 5px;
			    padding: 5px 10px;
			    cursor: pointer;
			}
		}
	}

	.btn-csv{

	}
}

.answer-question-detail-module,
.answer-user-detail-module{
	.answer {
		font-size: 18px;
	}
}

@media only screen and (max-width: 510px) {
    .store-info-logo {
        // margin-left: 60%;
    }
}

@media screen and (max-width: 455px) {
    .answer-question-list-module,
	.answer-user-list-module{
		.filter-container{
			flex-flow: column;
    		display: flex;

			.daterange-filter-wrapper{
				display: block;
				text-align: center;
				order: 2;

				&>div{
					display: block;
				}
			}

			.btn-csv{
				display: block;
				margin: 10px auto 20px auto;
			}
		}
	}
}

.home-icon {
	margin-top: 10px;
}

.store-list .list__tile{
    min-height: 100px !important;
  }

.custom-radio.input-group.radio{
	margin-bottom: 10px;

	label{
		height: auto;
	    position: relative;
	    max-width: 90%;
	    white-space: pre-line;
	    text-align: justify;
	}
	.input-group__input{
		position: absolute;
	}
}